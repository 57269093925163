import React, { useRef } from 'react';
import { Map } from 'immutable';
import { defineMessages, FormattedMessage, FormattedNumber, injectIntl } from 'react-intl';
import moment from 'moment';
import './ClosePO.css';
import { LINE_ITEM_TYPE, INVOICE_FIELD_TYPE } from 'constants/App';
import InvoiceInformation from '../components/InvoiceInformationClosedPO';
import TaxInformation from '../components/TaxInformationClosedPO';
import BankingInformation from '../components/BankingInformationClosedPO';
import * as actions from '../actions/ClosePO';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as constants from 'constants/App';
import ReactDOM from 'react-dom';
import Spinner from 'components/Spinner';
import classnames from 'classnames';
import * as helperFunctions from 'utils/HelperFunctions';
import { IntlProvider } from 'react-intl';
import *  as fieldValidations from 'utils/FieldValidations';
import { useAppState } from 'shared/AppState/app-state';
import configuration from 'shared/configuration';


const ClosePurchaseOrder = (props) => {
    const [appState] = useAppState();
    moment.locale(props.userLocale);
    const closePORef = useRef(null);

    const onInputChangeHandler = (key, value, e) => {

        var applicationTaxType = props.applicableTaxTypes !== undefined ? props.applicableTaxTypes.map((tax) => { return tax.toArray().toString().substring(0, 3) }).toArray().toString() : "";


        if (applicationTaxType === "" && key === "IS_TAX_EXEMPTED" && value === false) {
            props.dispatch(actions.showPopOverTaxExe(true));
        } else {
            props.dispatch(actions.showPopOverTaxExe(false));


            let poInvoiceDetails = Map({
                key: key,
                value: e != undefined && e.target.value != undefined ? e.target.value : value,
                taxType: e != undefined && e.target.attributes['data-taxType'] != undefined ? e.target.attributes['data-taxType'].value : '',

            });

            props.onChange(poInvoiceDetails);
        }
    }
    const onInputFocusHandler = (key) => {
        switch (key) {
            case key = "odometer": {
                props.dispatch(actions.showPopOverOdometer(false))
                break;
            }
            case key = "engineHours": {
                props.dispatch(actions.showPopOverEngineHours(false))
                break;
            }
            default:
                break;
        }
    }

    const onInputBlurHandler = (key) => {
        const { formatMessage } = props.intl;
        const messages = defineMessages({
            error_invoice_tax: {
                id: 'error_invoice_tax',
                description: 'Please enter tax.',
                defaultMessage: 'Please enter tax.'
            },
            error_tax_amount: {
                id: 'error_tax_amount',
                description: 'tax amount exceeds 15% of subtotal',
                defaultMessage: 'PO exceeds the tax threshold, Please call the call center at 1-866-274-1025'
            },
            error_invoice_tax_canada: {
                id: 'error_invoice_tax_canada',
                description: 'error_invoice_tax_canada',
                defaultMessage: 'Tax only applicable for {applicationTaxType} please correct.'
            }
        });

        var applicationTaxType = props.applicableTaxTypes !== undefined ? props.applicableTaxTypes.map((tax) => { return tax.toArray().toString().substring(0, 3) }).toArray().toString() : "";

        if (props.validationDetails.get('isFormValid') == undefined ||
            props.validationDetails.get('isFormValid') == false) {

            switch (key) {
                case key = "invoiceNo": {
                    props.validationDetails.get('invoiceNo') == false
                        ? props.dispatch(actions.showPopOverInvoiceNo(true))
                        : props.dispatch(actions.showPopOverInvoiceNo(false))
                }
                    break;
                case key = "invoiceDate": {
                    props.validationDetails.get('invoiceDate') == false
                        ? props.dispatch(actions.showPopOverInvoiceDate(true))
                        : props.dispatch(actions.showPopOverInvoiceDate(false))
                }
                    break;
                case key = "submittedBy": {
                    props.validationDetails.get('submittedBy') == false
                        ? props.dispatch(actions.showPopOverInvoiceName(true))
                        : props.dispatch(actions.showPopOverInvoiceName(false))
                }
                    break;
                case key = "repairCompletionDate": {
                    props.validationDetails.get('repairCompletionDate') == false
                        ? props.dispatch(actions.showPopOverRepairCompleteDate(true))
                        : props.dispatch(actions.showPopOverRepairCompleteDate(false))
                }
                    break;
                case key = "repairStartDate": {
                    props.validationDetails.get('repairStartDate') == false
                        ? props.dispatch(actions.showPopOverRepairStartDate(true))
                        : props.dispatch(actions.showPopOverRepairStartDate(false))
                }
                    break;
                case key = "odometer": {
                    props.validationDetails.get('odometer') == false || props.showOdometerWarning
                        ? props.dispatch(actions.showPopOverOdometer(true))
                        : props.dispatch(actions.showPopOverOdometer(false))
                }
                case key = "engineHours": {
                    props.showEngineHoursWarning
                        ? props.dispatch(actions.showPopOverEngineHours(true))
                        : props.dispatch(actions.showPopOverEngineHours(false))
                }
                    break;
                case key = "tax": {
                    let taxLimit = appState.overrideTaxThresholdLimit ? configuration.overrideTaxLimit : configuration.defaultTaxLimit;
                    if (props.invoiceDetails.get('isTaxExempted') == false && props.invoiceDetails.get('canShowMultipleTaxTypes') == false && props.validationDetails.get('taxAmount') == false && props.lineItemsTax == 0 && props.taxTotal < props.subTotal * taxLimit) {

                        props.dispatch(actions.setTaxErrorMessage(formatMessage({ ...messages.error_invoice_tax })));
                        props.dispatch(actions.showPopOverTax(true));
                    } else {
                        if (props.taxTotal > props.subTotal * taxLimit) {
                            props.dispatch(actions.setTaxErrorMessage(formatMessage({ ...messages.error_tax_amount })));
                            props.dispatch(actions.showPopOverTax(true));

                        } else {
                            props.dispatch(actions.showPopOverTax(false))

                        }
                    }
                }
                    break;
                case key = "taxCanada": {
                    let taxLimit = appState.overrideTaxThresholdLimit ? configuration.overrideTaxLimit : configuration.defaultTaxLimit;
                    if (props.invoiceDetails.get('isTaxExempted') == false && props.invoiceDetails.get('canShowMultipleTaxTypes') == true && props.validationDetails.get('taxAmount') == false && props.lineItemsTax == 0 && props.taxTotal < props.subTotal * taxLimit) {
                        props.dispatch(actions.setTaxErrorMessage(formatMessage({ ...messages.error_invoice_tax_canada }, { applicationTaxType: applicationTaxType })));
                        props.dispatch(actions.showPopOverTaxCanada(true));
                    } else{
                        if(props.taxTotal > props.subTotal * taxLimit){
                            props.dispatch(actions.setTaxErrorMessage(formatMessage({ ...messages.error_tax_amount })));
                            props.dispatch(actions.showPopOverTaxCanada(true));
                        }else{
                            props.dispatch(actions.showPopOverTaxCanada(false));
                        }
                    }
                }
                    break;

                case key = "taxExe": {
                    if (applicationTaxType === "" && props.invoiceDetails.get('isTaxExempted') == false) {
                        props.dispatch(actions.showPopOverTaxExe(true));
                    } else {
                        props.dispatch(actions.showPopOverTaxExe(false));
                    }

                }
                    break;

                case key = "refNumber": {
                    props.validationDetails.get('refNumber') == false ?
                        props.dispatch(actions.showPopOverRefNumber(true))
                        :
                        props.dispatch(actions.showPopOverRefNumber(false))
                }
                    break;
            }
        }

    }

    const onClosePOSaveHandler = (e) => {

        if (props.validationDetails.get('isFormValid') == undefined ||
            props.validationDetails.get('isFormValid') == false) {

            validateFormAndShowErrors();
            closePORef.current.scrollIntoView();
            return false;
        }
        else {
            props.onClosePO(props.invoiceDetails);
            return true;
        }
    }

    const validateFormAndShowErrors = () => {
        const { formatMessage } = props.intl;
        const messages = defineMessages({
            error_invoice_tax: {
                id: 'error_invoice_tax',
                description: 'Please enter tax.',
                defaultMessage: 'Please enter tax.'
            },
            error_tax_amount: {
                id: 'error_tax_amount',
                description: 'tax amount exceeds 15% of subtotal',
                defaultMessage: 'PO exceeds the tax threshold, Please call the call center at 1-866-274-1025'
            },
            error_invoice_tax_canada: {
                id: 'error_invoice_tax_canada',
                description: 'error_invoice_tax_canada',
                defaultMessage: 'Tax only applicable for {applicationTaxType} please correct.'
            }
        });
        var applicationTaxType = props.applicableTaxTypes !== undefined ? props.applicableTaxTypes.map((tax) => { return tax.toArray().toString().substring(0, 3) }).toArray().toString() : "";


        if (props.validationDetails.get('isFormValid') == undefined ||
            props.validationDetails.get('isFormValid') == false) {
            {
                props.validationDetails.get('invoiceNo') == false
                    ? props.dispatch(actions.showPopOverInvoiceNo(true))
                    : props.dispatch(actions.showPopOverInvoiceNo(false))
            }

            props.validationDetails.get('invoiceDate') == false
                ? props.dispatch(actions.showPopOverInvoiceDate(true))
                : props.dispatch(actions.showPopOverInvoiceDate(false))
            props.validationDetails.get('submittedBy') == false
                ? props.dispatch(actions.showPopOverInvoiceName(true))
                : props.dispatch(actions.showPopOverInvoiceName(false))

            props.validationDetails.get('repairCompletionDate') == false
                ? props.dispatch(actions.showPopOverRepairCompleteDate(true))
                : props.dispatch(actions.showPopOverRepairCompleteDate(false))

            props.validationDetails.get('repairStartDate') == false
                ? props.dispatch(actions.showPopOverRepairStartDate(true))
                : props.dispatch(actions.showPopOverRepairStartDate(false))

            props.validationDetails.get('odometer') == false || props.showOdometerWarning
                ? props.dispatch(actions.showPopOverOdometer(true))
                : props.dispatch(actions.showPopOverOdometer(false))

            if (props.showBankAccountRadioButton) {
                props.validationDetails.get('isPaymentCreditToAccount') == false
                    ? props.dispatch(actions.showPopOverPaymentCredit(true))
                    : props.dispatch(actions.showPopOverPaymentCredit(false))
            }


            props.validationDetails.get('isWorkCompleted') == false
                ? props.dispatch(actions.showPopOverWorkComplete(true))
                : props.dispatch(actions.showPopOverWorkComplete(false))


            if (props.showInvoiceRadioButton) {
                props.validationDetails.get('isInvoiceReproducible') == false
                    ? props.dispatch(actions.showPopOverStandard(true))
                    : props.dispatch(actions.showPopOverStandard(false))

            }

            if (fieldValidations.isRequired(props.fieldValidationsData, 'TAX')) {
                if (props.invoiceDetails.get('isTaxExempted') == false && props.invoiceDetails.get('canShowMultipleTaxTypes') == false && props.validationDetails.get('taxAmount') == false && Number(props.onTaxAmount) == 0) {

                    props.dispatch(actions.setTaxErrorMessage(formatMessage({ ...messages.error_invoice_tax })));
                    props.dispatch(actions.showPopOverTax(true));

                } else {
                    let taxLimit = appState.overrideTaxThresholdLimit ? configuration.overrideTaxLimit : configuration.defaultTaxLimit;
                    if (props.taxTotal > props.subTotal * taxLimit) {
                        props.dispatch(actions.setTaxErrorMessage(formatMessage({ ...messages.error_tax_amount })));
                        props.dispatch(actions.showPopOverTax(true));
                    } else {
                        props.dispatch(actions.showPopOverTax(false))
                    }
                }

                if (props.invoiceDetails.get('isTaxExempted') == false && props.invoiceDetails.get('canShowMultipleTaxTypes') == true && props.validationDetails.get('taxAmount') == false && Number(props.onTaxAmount) == 0) {
                    props.dispatch(actions.setTaxErrorMessage(formatMessage({ ...messages.error_invoice_tax_canada }, { applicationTaxType: applicationTaxType })));
                    props.dispatch(actions.showPopOverTaxCanada(true));
                } else{
                    let taxLimit = appState.overrideTaxThresholdLimit ? configuration.overrideTaxLimit : configuration.defaultTaxLimit;
                    if(props.taxTotal > props.subTotal * taxLimit){
                        props.dispatch(actions.setTaxErrorMessage(formatMessage({ ...messages.error_tax_amount })));
                        props.dispatch(actions.showPopOverTaxCanada(true));
                    }else{
                        props.dispatch(actions.showPopOverTaxCanada(false));
                    }
                }

                if (applicationTaxType === "" && props.invoiceDetails.get('isTaxExempted') == false) {
                    props.dispatch(actions.showPopOverTaxExe(true));
                } else {
                    props.dispatch(actions.showPopOverTaxExe(false));
                }
            }


            props.validationDetails.get('refNumber') == false ?
                props.dispatch(actions.showPopOverRefNumber(true))
                :
                props.dispatch(actions.showPopOverRefNumber(false));

        }

    }
    const messages = defineMessages({
        problem_with_close_po: {
            id: 'problem_with_close_po',
            description: 'Found a problem? Edit PO before closing',
            defaultMessage: 'Found a problem? Edit PO before closing'
        },
        close_po_submit_1: {
            id: 'close_po_submit_1',
            description: 'Request payment of ',
            defaultMessage: 'Request payment of '
        },
        close_po_submit_2: {
            id: 'close_po_submit_2',
            description: ' and close PO',
            defaultMessage: ' and close PO'
        },
        work_completed_tax_deferred_header: {
            id: 'work_completed_tax_deferred_header',
            description: 'header for wc tax deferred',
            defaultMessage: 'Tax amount to be added later.'
        },
        work_completed_tax_deferred_subtext: {
            id: 'work_completed_tax_deferred_subtext',
            description: 'Sub text for wc tax deferred',
            defaultMessage: 'If you believe you are seeing this message in error, please call 1-866-274-1025 to discuss with a Holman technician.'
        },
        work_completed_tax_deferred_subtext_can: {
            id: 'work_completed_tax_deferred_subtext_can',
            description: 'Sub text for wc tax deferred canada number',
            defaultMessage: 'If you believe you are seeing this message in error, please call 1-800-363-7676 to discuss with a Holman technician.'
        },
        problem_with_close_claim: {
            id: 'problem_with_close_claim',
            description: 'Found a problem? Edit Claim before closing',
            defaultMessage: 'Found a problem? Edit Claim before closing'
        },
    });

    const closePoTotal = helperFunctions.addFloats(props.subTotal, props.taxTotal);

    return (
        <div className="close-po" ref={closePORef}>
            <div className="close-po__invoice-details">
                <InvoiceInformation
                    invoiceDetails={props.invoiceDetails}
                    poCreatedDate={props.poCreatedDate}
                    onInputChangeHandler={onInputChangeHandler.bind(this)}
                    userLocale={props.userLocale}
                    popOverDisplayShowInvoiceNo={props.popOverDisplayShowInvoiceNo}
                    validationDetails={props.validationDetails}
                    onInputBlurHandler={onInputBlurHandler.bind(this)}
                    popOverDisplayShowInvoiceDate={props.popOverDisplayShowInvoiceDate}
                    popOverDisplayShowInvoiceName={props.popOverDisplayShowInvoiceName}
                    popOverDisplayShowRepairCompleteDate={props.popOverDisplayShowRepairCompleteDate}
                    popOverDisplayShowRepairStartDate={props.popOverDisplayShowRepairStartDate}
                    popOverDisplayShowOdometer={props.popOverDisplayShowOdometer}
                    popOverDisplayShowEngineHours={props.popOverDisplayShowEngineHours}
                    vehicleDetails={props.vehicleDetails}
                    vehicleDetailsForOdometerWarnings={props.vehicleDetailsForOdometerWarnings}
                    updateOdometerWarnings={props.updateOdometerWarnings}
                    updateEngineHoursWarnings={props.updateEngineHoursWarnings}
                    showOdometerWarning={props.showOdometerWarning}
                    showEngineHoursWarning={props.showEngineHoursWarning}
                    onInputFocusHandler={onInputFocusHandler.bind(this)}
                    allowWorkCompletion={props.allowWorkCompletion}
                    popOverDisplayShowRefNumber={props.popOverDisplayShowRefNumber}
                    vendor={props.vendor}
                    closePOVendorReferenceNo={props.closePOVendorReferenceNo}
                    fieldValidationsData={props.fieldValidationsData}

                />
                {fieldValidations.isVisible(props.fieldValidationsData, 'TAX') ?
                    <div>
                        <hr className="close-po__hr-style" />
                        <TaxInformation
                            approvedAmountTotal={props.approvedAmountTotal}
                            pendingAmountTotal={props.pendingAmountTotal}
                            invoiceDetails={props.invoiceDetails}
                            onInputChangeHandler={onInputChangeHandler.bind(this)}
                            validationDetails={props.validationDetails}
                            taxAmount={props.taxAmount}
                            popOverDisplayShowTax={props.popOverDisplayShowTax}
                            popOverDisplayShowTaxCanada={props.popOverDisplayShowTaxCanada}
                            popOverDisplayShowTaxExe={props.popOverDisplayShowTaxExe}
                            onInputBlurHandler={onInputBlurHandler.bind(this)}
                            onTotalAmount={props.onTotalAmount}
                            onTaxAmount={props.onTaxAmount}
                            taxErrorMessage={props.taxErrorMessage}
                            applicableTaxTypes={props.applicableTaxTypes}
                            uiTaxes={props.uiTaxes}
                        />
                    </div>
                    : <div>
                        <hr className="close-po__hr-style" />
                        <div className="close-po__tax_deferred">
                            <FormattedMessage {...messages.work_completed_tax_deferred_header} />
                            <p>&nbsp;</p>
                            {props.selectedCountry == 'USA' ?
                                <FormattedMessage {...messages.work_completed_tax_deferred_subtext} /> :
                                <FormattedMessage {...messages.work_completed_tax_deferred_subtext_can} />
                            }
                        </div>
                    </div>
                }
                <hr className="close-po__hr-style" />
                <BankingInformation
                    approvedAmountTotal={props.approvedAmountTotal}
                    pendingAmountTotal={props.pendingAmountTotal}
                    vendor={props.vendor}
                    invoiceDetails={props.invoiceDetails}
                    validationDetails={props.validationDetails}
                    onInputChangeHandler={onInputChangeHandler.bind(this)}
                    taxAmount={props.taxAmount}
                    lineItemsTax={props.lineItemsTax}
                    popOverDisplayShowPaymentCredit={props.popOverDisplayShowPaymentCredit}
                    popOverDisplayShowWorkComplete={props.popOverDisplayShowWorkComplete}
                    popOverDisplayShowStandard={props.popOverDisplayShowStandard}
                    subTotal={props.subTotal}
                    taxTotal={props.taxTotal}
                    showBankAccountRadioButton={props.showBankAccountRadioButton}
                    showInvoiceRadioButton={props.showInvoiceRadioButton}
                    selectedCurrency={props.selectedCurrency}
                />
            </div>

            <div tabIndex="16" className={classnames(
                { 'close-po__request_payment submit': !props.isClosingPO },
                { 'close-po__request_payment-disable submit': props.isClosingPO })} role="button"
                onClick={onClosePOSaveHandler.bind(this)}>
                <FormattedMessage {...messages.close_po_submit_1} />
                <IntlProvider locale="en">
                    <FormattedNumber value={closePoTotal} style="currency"
                        currency={props.selectedCurrency} />
                </IntlProvider>
                <FormattedMessage {...messages.close_po_submit_2} />
                <img src="/assets/VMS_33button_go_blue.png" />
                <div className="close-po__request_payment inline-block">
                    {props.isClosingPO ?
                        <Spinner spinnerType='blue' />
                        : undefined
                    }
                </div>
            </div>
            <p>&nbsp;</p>

            {
                props.allowEditPO ?
                    <div tabIndex="18" className="close-po__report-problem submit" role="button"
                        onClick={props.onEditPO}>
                        <FormattedMessage {...messages.problem_with_close_po} />
                        <img src="/assets/VMS_28button_go_clear.png" />
                    </div>
                    : false
            }

            {
                props.location.state?.estimateId ?
                    <Link
                        tabIndex="18"
                        className="close-po__report-problem submit"
                        style={{ display: "flex", justifyContent: "flex-end" }}
                        to={`/AccidentManagement/AccidentAssignments/${props.location.state.estimateId}`}>
                        <FormattedMessage {...messages.problem_with_close_claim} />
                        <img src="/assets/VMS_28button_go_clear.png" />
                    </Link>
                    : null
            }

        </div>
    );
}
;


export default withRouter(connect(null)(injectIntl((ClosePurchaseOrder))));
